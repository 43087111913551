export const last_name = 'שם משפחה';
export const first_name = 'שם פרטי';
export const street = 'רחוב';
export const num = 'מספר';
export const ishuv = 'ישוב';
export const phone = 'טלפון';
export const mobilePhone = 'נייד';
export const id = 'מס. ת.ז. (ללא 0 בהתחלה)';
export const displayId = 'מספר ת.ז.';
export const colel = 'שם כולל';
export const children_num = 'מס. ילדים';
export const bankNum = 'מספר בנק';
export const bankBranchNum = 'מס. סניף';
export const bankAccount = 'מס. חשבון';
export const exists_card = 'מספר כרטיס אידיש כארד באם קיים';
export const exists_card_2 = 'מספר כרטיס אידיש כארד2 באם קיים';
export const display_exists_card = '1אידיש כארד';
export const display_exists_card2 = '2אידיש כארד';
export const food_chain = 'רשת מזון';
export const sum = 'סכום (לאחר אישור מול המערכת)';
export const displaySum = 'סכום';
export const comments = 'הערות';

export const FIELD_TO_MAX_CHARS = [
    { 'שם משפחה': 20 },
    { 'שם פרטי': 20 },
    { 'רחוב': 20 },
    { 'מספר': 4 },
    { 'ישוב': 20 },
    { 'טלפון': 10 },
    { 'נייד': 10 },
    { 'מס. ת.ז. (ללא 0 בהתחלה)' : 9 },
    { 'שם כולל': 20 },
    { 'מספר ילדים': 2 },
    { 'מספר בנק': 5 },
    { 'מספר סניף': 9 },
    { 'מספר חשבון': 9 },
    { 'מספר כרטיס אידיש כארד באם קיים': 11 },
    { 'מספר כרטיס אידיש כארד2 באם קיים': 11 },
    { 'סכום (לאחר אישור מול המערכת)': 5 },
    { 'רשת מזון': 18 }
];

export const CSV_HEADERS = [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    id,
    colel,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    exists_card,
    exists_card_2,
    sum,
    food_chain
];

export const RESULT_CSV_HEADERS = [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    id,
    colel,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    exists_card,
    exists_card_2,
    sum,
    food_chain,
    comments
];

export const LIST_COLUMNS = [
    ishuv,
    colel,
    food_chain
];

export const REQUIRED_FIELDS = (user) => [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    id,
    colel,
    children_num,
    sum,
    ...(user.foodChain == null ? [food_chain] : [])
];

export const INT_FIELDS = [
    num,
    id,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    exists_card,
    exists_card_2,
    sum,
    phone,
    mobilePhone
];

export const TEXT_FIELDS = [
    food_chain, colel, ishuv
];

export const TABLE_HEADERS = [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    displayId,
    colel,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    display_exists_card,
    display_exists_card2,
    displaySum,
    food_chain
];

export const RESULT_TABLE_HEADERS = [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    displayId,
    colel,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    display_exists_card,
    display_exists_card2,
    displaySum,
    food_chain,
    comments
];
